.sidebar {
  margin: 0;
  padding: 0;
  width: 300px;
  background-color: var(--white-color);
  position: fixed;
  height: 100%;
  overflow: auto;
  border: 1px solid #dce0e4;
  z-index: 2;
}

.manual-body a {
  display: block;
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-color);
  padding: 16px;
  text-decoration: none;
}

.manual-body a.active {
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--white-color);
}
.manual-body a:hover:not(.active) {
  color: var(--secondary-color);
}
.accordion-item {
  border: 0px !important;
  /** -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop; **/
}
.accordion-button {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px;
  color: var(--text-color) !important;
  padding: 16px !important;
  text-decoration: none;
}
.accordion-button:not(.collapsed) {
  border-radius: 5px;
  box-shadow: none !important;
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
.accordion-button:not(.collapsed) img,
.accordion-button:focus img,
.manual-body a.active img {
  filter: brightness(5);
}
.accordion-button:not(.collapsed)::after {
  filter: brightness(18);
}
.accordion-button:active,
.accordion-button:focus {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.accordion-button:hover {
  color: var(--secondary-color) !important;
}
.dropdown-body {
  background: #fafbfe;
}
.dropdown-body a {
  display: block;
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--text-color);
  text-decoration: none;
}

.dropdown-body a.active {
  color: var(--primary-color);
}
.dropdown-body a:hover:not(.active) {
  color: var(--secondary-color) !important;
}

.accordion-img {
  height: 24px;
  padding-right: 1.3rem;
}
.list-img {
  height: 15px;
  padding-bottom: 4px;
  padding-right: 1.5rem;
}

/**
.accordion-button:not(.collapsed)::after {
  background-image: url("../../Assets/icons/arrow.png") !important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='2727'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.accordion-button:not(.collapsed)::after , 
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
 **/
@media screen and (max-width: 700px) {
  /* .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  } */
  /**
  .sidebar a {
    float: left;
  } **/
}

@media screen and (max-width: 400px) {
  /**
  .sidebar a {
    text-align: center;
    float: none;
  } **/
}
