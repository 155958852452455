.purchase-price-btn {
  border: 0px;
  border-radius: 5px;
  height: 20px;
  width: 52px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--white-color);
}

.active-btn {
  background: #ff9900;
}

.inactive-btn {
  background: rgba(255, 153, 0, 0.5);
}

.inside-table {
  background: #fff5e6;
  border-radius: 5px;
  font-size: 14px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1));
}

/* My Custom Code */
.selectedSearchItemHover {
  background-color: #ffcc81 !important;
  border-radius: 5px !important;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1)) !important;
}
