.product-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-color);
  margin-top: 3.4vh;
  /**margin-left: 3.4vh; **/
}

.product-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /**margin-left: 3.4vh; **/
  color: var(--text-color);
}
.add-product-button {
  /**margin-right: 30px; **/
  margin-top: 3.4vh;
  background: var(--secondary-color);
  border-radius: 5px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  height: 50px;
}
.product-card {
  background: var(--white-color);
  border: 1px solid #dce0e4;
  border-radius: 5px;
  margin-top: 3vh;
  /*
 * margin-left: 3.4vh;
  margin-right: 3.4vh; 
 **/
}

.form-label {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000000 !important;
}

.form-control,
.form-select {
  height: 46px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #637381 !important;
  font-size: 14px !important;
  opacity: 1 !important; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #637381 !important;
  font-size: 14px !important;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #637381 !important;
  font-size: 14px !important;
}
.image-upload-form {
  height: 39px;
}
.submit-btn {
  width: 100%;
  background: var(--secondary-color) !important;
  border-radius: 5px;
  height: 57px;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.cancel-btn {
  width: 100%;
  background: var(--text-color) !important;
  border-radius: 5px;
  height: 57px;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.preview-btn {
  width: 100%;
  background: #2fde5a !important;
  border-radius: 5px;
  height: 57px;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.disable-submit-btn {
  width: 100%;
  height: 57px;
}

.search-bar {
  margin-left: 2.6vh;
  background: #ffffff;
  border: 1px solid #dce0e4;
  border-radius: 5px;
  width: 50% !important;
  height: 43px;
  padding: 0.6rem;
}

.table-header {
  background: #fafbfe;
}
.category-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-color);
  margin-top: 0.4vh;
  /**margin-left: 3.4vh; **/
}
.create-btn {
  width: 100%;
  background: var(--secondary-color) !important;
  border-radius: 5px;
  height: 57px;
  font-weight: 600 !important;
  /** font-size: 20px !important; **/
  line-height: 24px !important;
}

.product-details-right-section {
  margin-right: 2vh !important;
}
.print-button {
  background: var(--secondary-color);
  border-radius: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  height: 55px;
}
.image-upload-text {
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color);
}
.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fadeInTop {
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop;
}
.action-icon:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0px 3px;
  //margin-left: 0px;
}
.form-check-input:checked {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.pagination {
  //position: absolute;
  //bottom: 30px;
  //right: 30px;
  list-style: none;
  display: flex;
  justify-content: end;
  //align-items: center;
  margin-top: 4rem;
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  background-color: var(--text-color);
  color: #fff;
  text-decoration: none;
}
.pagination .hide {
  display: none;
}
.pagination .page-num:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.required-field {
  color: var(--secondary-color);
  padding-left: 0.1rem;
}

.pagination .active {
  background-color: var(--secondary-color);
  color: #fff;
}
@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.autocomplete_items {
  position: absolute;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 25px);
  /* margin-top: -15px;
  top: 100%; */
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 7px;
}

.autocomplete_items::-webkit-scrollbar {
  display: none;
}

.autocomplete_items {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.autocomplete_items div {
  cursor: pointer;
}

.autocomplete_items div:hover {
  background-color: #e9e9e99a;
}

.report-btn {
  width: 100%;
  background: var(--secondary-color) !important;
  border-radius: 5px;
  height: 46px;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  margin-top: 30px;
}

.product-img {
  height: 35px !important;
  width: 35px !important;
}

@media screen and (max-width: 1380px) {
  table {
    font-size: 14px;
  }
  td,
  th {
    font-size: 12px;
  }
}

@media screen and (max-width: 990px) {
  .product-details-right-section {
    margin-right: 2vh !important;
    margin-left: 2vh !important;
  }
  .report-btn {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 775px) {
  .pagination {
    //position: absolute;
    //bottom: 30px;
    //right: 30px;
    list-style: none;
    display: flex;
    justify-content: end;
    //align-items: center;
    margin-top: 4rem;
    font-size: 0.8rem;
    gap: 5px;
  }
  .pagination .page-num {
    padding: 4px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
    background-color: rgba(0, 117, 191, 0.1);
    color: var(--secondary-color);
    text-decoration: none;
  }
  .pagination .page-num:hover {
    background-color: var(--secondary-color);
    color: #fff;
  }
  .pagination .active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

@media print {
  @page {
    size: portrait !important;
    margin: 5px 0px 10px 0px !important;
  }
  .hide-print {
    display: none;
  }
  .show-print {
    display: block;
    padding: 0px;
    margin: 0px;
  }
  .product-card {
    margin: 0px;
    padding: 0px;
  }
}
