.single-void-reason-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.single-void-reason-checkbox {
  width: 22px !important;
  height: 22px !important;
  aspect-ratio: 1 / 1;
}

.single-void-reason-content {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

/* input[type="checkbox"] {
  accent-color: #ff9900 !important;
  color: white !important;
} */

.single-void-reason-container > input[type="checkbox"] {
  background-color: white !important;
  border: 1px solid #dce0e4 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}
.single-void-reason-container > input[type="checkbox"]:checked {
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
}

.single-void-reason-container > input[type="checkbox"]:checked:after {
  margin-top: 5px;
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><path d="M3.78125 12.0312L8.59375 16.8438L18.2188 6.53125" stroke="white" stroke-width="2.34375" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
