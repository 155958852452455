.transfer-table {
  border: 1px solid #dee2e6;
}

.table-data-input-field {
  width: 60px;
  padding-left: 5px;
  border: 1px solid gray;
  border-radius: 5px;
}
.add-product-btn {
  width: 100%;
  background: var(--secondary-color) !important;
  border-radius: 5px;
  height: 48px;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  margin: 30px 0px;
}

/* My Custom Code */
.selectedSearchItemHover {
  background-color: #ffcc81 !important;
  border-radius: 5px !important;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1)) !important;
}
.add-void-input-field {
  width: 60px;
  padding-left: 5px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}
