.main-content-header {
  min-height: 13vh;
  background-color: var(--white-color);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  border-left: 1px solid #dce0e4;
}
span {
  font-size: 20px;
  line-height: 24px;
}
.user-icon {
  height: 65px;
}

.brand-logo {
  height: 50px;
  cursor: pointer;
}

.nav-dropdown {
  font-size: 20px;
}

@media screen and (max-width: 860px) {
  .brand-logo {
    height: 40px;
  }
  span {
    font-size: 15px;
    line-height: 18px;
  }
  .user-icon {
    height: 40px;
  }
}

@media screen and (max-width: 500px) {
  .nav-dropdown {
    font-size: 12px;
  }
  span {
    font-size: 12px;
  }
  .dropdown-item {
    font-size: 12px;
  }
  .user-icon {
    height: 30px;
  }
  .dropdown-menu {
    --bs-dropdown-min-width: 5rem !important;
  }
}
