.dashborad-card {
  margin-top: 3vh;
  background: var(--white-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border: 0px;
  border-radius: 5px;
}
.dashboard-card-image {
  height: 80px;
  width: 80px;
}
.dashboard-card-para {
  margin-left: 25px;
  margin-top: 15px;
}
.dashboard-card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: var(--text-color);
}
.dashboard-card-count {
  font-weight: 600;
  font-size: 20px;
  line-height: 2px;
  color: #122e40;
}

.dashboard-color-card-para {
  margin-top: 15px;
}

.dashboard-card-header {
  font-weight: 950 !important;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}
.analysis-card {
  height: 37.35vh;
}
/* .dropdown-menu {
  --bs-dropdown-min-width: 9.5rem !important;
} */
@media screen and (max-width: 450px) {
  .analysis-card {
    height: 150px;
  }
}
