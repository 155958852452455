.footer-content {
  background: var(--white-color);
  height: 10vh;
  border-top: 1px solid #dce0e4;
  border-radius: 5px;
  margin-left: var(--main-content-margin);
}
.footer-text {
  margin-top: 4vh;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

/* @media screen and (max-width: 700px) {
  footer {
    margin-left: 0;
  }
} */
