.select-bar {
  margin-left: 1vh;
  background: #ffffff;
  border: 1px solid #dce0e4;
  border-radius: 5px;
  width: 40% !important;
  height: 43px;
  //padding: 0.6rem;
  background: url("../../Assets/icons/report.png") no-repeat scroll 5px 11px;
  background-size: 20px;
  padding-left: 25px;
}

.select-product-type {
  margin-left: 1vh;
  background: #ffffff;
  border: 1px solid #dce0e4;
  border-radius: 5px;
  width: 25% !important;
  height: 43px;
  //padding: 0.6rem;
  background: url("../../Assets/icons/productType.png") no-repeat scroll 5px
    11px;
  background-size: 20px;
  padding-left: 25px;
}
.search-product {
  margin-left: 1vh;
  background: #ffffff;
  border: 1px solid #dce0e4;
  border-radius: 5px;
  width: 25% !important;
  height: 43px;
  padding: 0.6rem;
}
.generate-btn {
  margin-left: 1vh;
  width: 20%;
  background: var(--secondary-color) !important;
  border-radius: 5px;
  height: 43px;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.report-table-header {
  background: #fafbfe;
  font-size: 12px;
}

.report-table-body {
  font-size: 12px;
}

.report-form-label {
  font-size: 12px !important;
  line-height: 8px !important;
}

.report-page-card {
  padding: 1rem;
}
.report-border {
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
.generic-input {
  //margin-left: 1vh;
  background: #ffffff;
  border: 1px solid #dce0e4;
  border-radius: 5px;
  width: 25% !important;
  height: 43px;
  padding: 0.6rem;
}
.percentage-input {
  background: #ffffff;
  border: 1px solid #dce0e4;
  border-radius: 5px;
  width: 10% !important;
  height: 43px;
  padding: 0.6rem;
}
.percentage-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #637381 !important;
  font-size: 10px !important;
  opacity: 1 !important; /* Firefox */
}

.percentage-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #637381 !important;
  font-size: 10px !important;
}

.percentage-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #637381 !important;
  font-size: 10px !important;
}
.grand-total-row {
  font-weight: 600;
}
/* @media print {
  @page {
    size: landscape;
  }
  .hide-print {
    display: none;
  }
  .show-print {
    display: block;
    padding: 0px;
    margin: 0px;
    //width: 100%;
    //position: absolute;
    //margin-left: 0px;
  }
  .report-page-card {
    padding: 0rem;
  }
  .card {
    padding: none !important;
    margin: none !important;
    border: none !important;
  }
  .table {
    border: 1px solid !important;
  }
  h4 {
    text-align: center;
  }
} */

@media screen and (max-width: 1380px) {
  .select-product-type,
  .select-bar,
  .search-product {
    font-size: 12px;
  }
}

@media screen and (max-width: 1199px) {
  .generate-btn {
    width: 20%;
  }
}

@media screen and (max-width: 991px) {
  .generate-btn {
    width: 25%;
    //margin-top: 5px;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 576px) {
  .generate-btn {
    width: 30%;
  }
}
