.table-container {
  max-height: 500px; /* Set the desired maximum height for the table body */
  overflow-y: scroll;
}

.table thead {
  position: sticky !important;
  top: -1px !important;
  background-color: #fafbfe; /* Set the desired background color for the header */
  z-index: 1 !important;
}

.table th {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
}
