.login-navbar {
  min-height: 16vh;
  background-color: var(--white-color);
  box-shadow: 0px 7px 28px rgba(0, 0, 0, 0.2);
}

.login-navbar img {
  max-height: 8.5vh;
}

.form_container input {
  width: 585px;
  height: 55px;
  border: 1px solid #eff0f2;
  border-radius: 5px;
  padding: 0 10px;
  background: #fcfcfc;
  font-weight: 400;
  font-size: 2.3vh;
}
.form_container button {
  width: 585px;
  height: 55px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
  font-weight: 700;
  font-size: 2.6vh;
  color: var(--white-color);
  box-shadow: 0px 8px 12px rgba(255, 153, 0, 0.3);
}

@media screen and (max-width: 991px) {
  .login-container {
    padding-top: 20%;
  }
  .form_container input,
  .form_container button {
    width: 500px;
    font-size: 2vh;
  }
}
@media screen and (max-width: 500px) {
  .form_container input,
  .form_container button {
    width: 85vw;
  }
}
