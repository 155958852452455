/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@400&display=swap");

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

a,
a:hover {
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #1b2850;
  --secondary-color: #ff9900;
  --background-color: #fafbfe;
  --text-color: #637381;
  --white-color: #fff;
  --black-color: #000;
  --primary-font: "Montserrat", sans-serif;
  --main-content-margin: 300px;
  --toastify-color-success: var(--secondary-color) !important;
}

html {
  scroll-behavior: smooth;
  height: 100vh;
  /* overflow-x: hidden; */
}

body {
  font-family: var(--primary-font) !important;
  font-size: 100% !important;
  font-weight: 400 !important;
  overflow-x: hidden !important;
  background-color: var(--background-color) !important;
  min-height: 100vh !important;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  background: var(--white-color);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

h1 {
  font-family: var(--primary-font) !important;
  font-size: 3rem !important;
}

h2 {
  font-family: var(--primary-font) !important;
  font-size: 2.5rem !important;
}

h4 {
  font-family: var(--primary-font) !important;
}

h5 {
  font-family: var(--primary-font) !important;
  font-size: 1rem !important;
}

p {
  font-family: var(--primary-font) !important;
}
.container-view {
  margin-left: 2vh !important;
  margin-right: 2vh !important;
}
.mainContent {
  margin-left: var(--main-content-margin);
  position: relative;
  min-height: 100vh;
  /* overflow: auto; */
  overflow-x: hidden;
}
.header-container-view {
  margin-left: 2vh !important;
  margin-right: 2vh !important;
}
.Toastify__progress-bar--success {
  background: var(--secondary-color) !important;
}

.modal-open {
  padding-right: 0px !important;
}

@media screen and (max-width: 768px) {
  .mainContent {
    margin-left: 0;
  }
}

@media screen and (max-width: 500px) {
  .header-container-view {
    margin-left: 0vh !important;
    margin-right: 0vh !important;
  }
}

@media print {
  .mainContent {
    margin: 0px;
  }
  .container-view {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px !important;
  }
}
