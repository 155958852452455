.void-reasons {
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 10px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  margin-bottom: 15px !important;
  padding: 15px;
}

.void-reasons-comment {
  width: 100% !important;
  height: 100% !important;
  min-height: 80px;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #dce0e4;
}
